/*
 * @Author: liyu
 * @Date: 2022-05-18 15:25:12
 * @LastEditTime: 2022-05-18 18:29:52
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/solutions/retailSuper/index.tsx
 */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import { RetailBrandState } from '../retail/index.zh'
import Layout from 'layouts/zh'
import * as styles from './index.module.less'

const RetailSuper: React.FC = (props) => {
  const { retailSuperYaml: datas } = useStaticQuery(graphql`
    query {
      retailSuperYaml {
        title
        description
        keywords
        saTitle
        banner_title
        banner_Desc
        banner_buttonText
        banner_buttonHref
        banner_titleMb
        banner_sectionTwoTitle
        banner_sectionTwoTitleMb
        banner_sectionTwoDesc
        banner_qrcodeButtonText
        banner_icon {
          publicURL
        }
        banner_icon_mb {
          publicURL
        }
        banner_dataSource {
          dataTitle
          buttonText
          buttonHref
          qrcodeUrl {
            publicURL
          }
          descList
        }
        banner_list {
          url
          name
          isActive
        }
        software_title
        software_titleMb
        software_sectionCardList {
          cover {
            publicURL
          }
          title
          desc
        }
        software_buttonText
        software_qrcode {
          publicURL
        }
        panorama_title
        panorama_titleMb
        panorama_desc
        panorama_btnText
        panorama_btnCode {
          publicURL
        }
        panorama_imgUrl {
          publicURL
        }
        panorama_btnText_two
        panorama_btnText_two_url
        cargo_title
        cargo_titleMb
        cargo_dataList {
          dataMsg
          dataTitle
          btnText
          btnHref
          imgUrl {
            publicURL
          }
          imgUrlMb {
            publicURL
          }
        }
        digitization_title
        digitization_desc
        digitization_btnText
        digitization_btnHref
        digitization_orderHref
        digitization_orderText
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl {
            publicURL
          }
          imgUrlMb {
            publicURL
          }
        }
        product_title
        product_titleMb
      }
    }
  `)

  return (
    <Layout {...props}>
      <RetailBrandState className={classnames(styles.RetailSuper)} retailBrandYaml={datas} xGap={93} />
    </Layout>
  )
}

export default RetailSuper
